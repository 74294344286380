import "styles"
import "hotkeys"

const fontsContext = require.context('fonts/', true, /\.css$/)
fontsContext.keys().forEach(fontsContext)

// Import all javascript files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /.js$/)
componentsContext.keys().forEach(componentsContext)

import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';

document.addEventListener('DOMContentLoaded', () => {
  if(!window.matchMedia('(prefers-reduced-motion)').matches && document.getElementById("swup")) {
    const swup = new Swup({
      linkSelector: "a[data-swup]",
      containers: ["#swup", ".swup-replace"],
      plugins: [new SwupPreloadPlugin()]
    })
  }
})