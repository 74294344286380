export class HotKeys {  
  constructor() {
    document.addEventListener('DOMContentLoaded', this.configureListener.bind(this))
  }
  
  configureListener() {  
    this.listener = new KeyListener(this.keyPressed.bind(this))
  }
  
  keyPressed(key) {
    let link = document.querySelector(`a[data-hotkey=${key}]`)
    if (window.getComputedStyle(link).visibility == "hidden") {
      return
    }
    link?.click()
  }  
}

const KeyNames = {
  37: 'left',
  39: 'right'
}

class KeyListener {
  constructor(handler) {
    this.handler = handler
    
    document.addEventListener("keydown", this.handleKeyPress.bind(this))
  }
    
  handleKeyPress(event) {
    let key = KeyNames[event.keyCode]
    if (key) {
      this.handler(key)
    }
  }
}